import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const titleStyle = {
  fontFamily: "inter",
  fontSize: "16px",
  fontWeight: "600",
  fontStyle: "normal",
  lineHeight: "24px",
  marginBottom: "4px"
}

const cardStyle = {
  borderRadius: "8px",
  border: "1px solid rgb(234, 236, 240)",
  padding: "24px 16px",
  width: "100%",
  marginTop: "32px",
  background: "white",
  display: "grid",
  gridTemplateColumns: "1fr 200px",
  gap : "16px"
}

const buttonStyle = {
  border: "1px solid rgb(7, 43, 21)",
  background: "white",
  padding: "8px 32px",
  borderRadius: "4px",
}

const linkStyle = {
  "color": "rgb(7, 43, 21)",
  "fontWeight"  :"600",

}
const ExamCard = ({ type }) => {
  const { t } = useTranslation();
  let tests = {
    assignedMQAssessments: {
      title: t("Assessment Types.MCQ Assessment"),
      subtitle:
      t("Assessment Types.A Comprehensive Evaluation of Your Knowledge and Skills, Covering Problem-Solving, and Critical Thinking"),
      action: "/allAssessments",
    },
    assignedDEAssessments: {
      title: t("Assessment Types.Listening Assessment"),
      subtitle:
      t("Assessment Types.Evaluating Your Ability to understand and respond to spoken information"),
      action: "/dataEntryAssessment",
    },
    assignedTSAssessments: {
      title: t("Assessment Types.Typing Speed Assessment"),
      subtitle:
      t("Assessment Types.Demonstrate your Keyboard proficiency and accuracy through a series of Typing Assessments"),
      action: "/typingSpeedTest",
    },
    assignedEWAssessments: {
      title: t("Assessment Types.Article Writing Assessment"),
      subtitle:
      t("Assessment Types.Demonstrate your Writing Skills and Creativity Through a series of Writing Prompts and Tasks."),
      action: "/articleWritingAssessment",
    },
    assignedSAAssessments: {
      title: t("Assessment Types.Speech Assessment"),
      subtitle:
      t("Assessment Types.Demonstrate your Oral Communication Skills through a series of Spoken Tasks"),
      action: "/speechVoiceAssessment",
    },
  };
  return <div style={cardStyle}>
    <div>
      <p style={titleStyle}>
        {tests[type]?.title}
      </p>
      <p style={{marginBottom:"0px"}}>{tests[type]?.subtitle}</p>
      {/* <Typography variant="h5" component="div">
        Assessment Philippine English 1
        </p> */}
    </div>
    <div>
      <button style={buttonStyle}>
        <Link to={tests[type]?.action}><span style={linkStyle}>{t('Assessment Types.Enter Module')}</span></Link>
      </button>
    </div>
  </div>
};





//
const AssessmentModuleTypeMain_v1 = ({ user }) => {
  const { t, i18n } = useTranslation();
  function changeLanguage(lng) {
    i18n.changeLanguage(lng);
  }

useEffect(() => {
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  changeLanguage(selectedLanguage);
}, []);
 
  return (
    <div style={{position: "relative"}}>
      <h1>{t('Assessment Types.Assigned Assessments')}</h1>
      {user && !user.assignedMQAssessments && !user.assignedDEAssessments && !user.assignedTSAssessments && !user.assignedEWAssessments && !user.assignedSAAssessments  && <div className="noResults"><img src="https://d3lslnw17kc5b9.cloudfront.net/images/no-data.svg" title="No Results" alt="No Results"/></div>}
      {user && user.assignedMQAssessments && (
        <ExamCard type="assignedMQAssessments" />
      )}
      {user && user.assignedDEAssessments && (
        <ExamCard type="assignedDEAssessments" />
      )}
      {user && user.assignedTSAssessments && (
        <ExamCard type="assignedTSAssessments" />
      )}
      {user && user.assignedEWAssessments && (
        <ExamCard type="assignedEWAssessments" />
      )}
      {user && i18n.language === "en" && user.assignedSAAssessments && (
        <ExamCard type="assignedSAAssessments" />
      )}





    </div>
  );
};

export default AssessmentModuleTypeMain_v1;
