// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".app_container__1o_Eg {\n  /* display: flex; */\n  display: block;\n  align-items: center;\n  justify-content: center;\n  /* border: 5px solid #907d7d; */\n  margin: 0 20px;\n  padding: 20px;\n  border-radius: 20px;\n}\n\n.app_leftSideInContainer__2CyuS {\n  /* width: 20%; */\n}\n\n.app_rightSideInContainer__1QYCY {\n  /* width: 79%; */\n}\n\n/* .startButton {\n  margin: 30px auto;\n  cursor: pointer;\n  width: 150px;\n  height: 150px;\n  text-align: center;\n  font-size: 30px;\n  font-weight: 500;\n  border-radius: 50%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n} */\n\n\n.app_startButton__3wwuv {\n  margin: 20px auto 10px auto!important;\n  height: 50px!important;\n  border-radius: 0!important;\n  font-size: 15px!important;\n  border-radius: 1.5rem!important;\n    background: #4258b3!important;\n    color: #fff;\n    width: 200px;\n    display: flex;\n    align-items: center;\n    border: none!important;\n    text-align: center;\n    justify-content: center;\n    cursor: pointer;\n    transition: all 0.5s ease-in-out 0s;\n}\n.app_startButton__3wwuv:hover {\n  background: #243b98!important;\n  transform: translate(0, -10px);\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}", ""]);
// Exports
exports.locals = {
	"container": "app_container__1o_Eg",
	"leftSideInContainer": "app_leftSideInContainer__2CyuS",
	"rightSideInContainer": "app_rightSideInContainer__1QYCY",
	"startButton": "app_startButton__3wwuv"
};
module.exports = exports;
