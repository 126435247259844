import React, { useEffect, useState } from "react";
import "../designStyles/NavBar.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { Language } from "../bank/PredifinedUserLocations";
import { confirmAlert } from "react-confirm-alert";

const NavBar = ({ user }) => {
  const { t, i18n } = useTranslation();
 const [selectedLanguage, setSelectedLanguage] = useState("");

 useEffect(() => {
  const storage = localStorage.getItem("selectedLanguage") || "en";
  let languageData = {
    id: storage === "fr" ? 2 : storage === "ar" ? 3 : 1,
    value: storage || "en",
    label: storage === "fr" ? "Français" : storage === "ar" ? "عربي" : "English"
}
   setSelectedLanguage(languageData);
   localStorage.setItem('selectedLanguage', storage);
 }, []);

const handleLanguageChange = async (lng) => {
  i18n.changeLanguage(lng?.value);
  setSelectedLanguage(lng);
  localStorage.setItem('selectedLanguage', lng?.value);
};

const handleAlert = () => {
  confirmAlert({
    title: t("Side Navbar.Confirm logout"),
    message: t("Side Navbar.Are you sure you want to log out?"),
    closeOnEscape: false,
    closeOnClickOutside: false,
    buttons: [
      {
        className: "mdk-confirm-yes",
        label: t("mcqAssisment.Yes"),
        onClick: () => {
          window.location= "/logout"
        },
      },
      {
        className: "mdk-confirm-no",
        label: t("mcqAssisment.No"),
        onClick: () => {},
      },
    ],
  });
};
  return (
    <React.Fragment>
      <nav id="sidebar">
        <div className="sidebar-header">
          <img src="../images/logo.png" alt="" />
        </div>
        <ul className="list-unstyled components">
          <li>
            {user && user.isAdmin === true && (
              <NavLink className="mdk-nav" to="/mainDashboard">
                <i className="fa fa-home" aria-hidden="true"></i>
                {t("Side Navbar.Dashboard")}
              </NavLink>
            )}
          </li>
          <li>
            <NavLink className="mdk-nav" to="/allAssessmentTypes">
              <i className="fa fa-book" aria-hidden="true"></i>
              {t("Side Navbar.Assessment Types")}
            </NavLink>
          </li>

          <li>
            {user && user.isAdmin === true && (
              <NavLink className="mdk-nav" to="/advancedAnalytics" style={{lineHeight: "95%"}}>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
                {t("Side Navbar.Custom Analytics")}
              </NavLink>
            )}
          </li>
          <li>
            {user && (
              <li className="mdk-nav" onClick={handleAlert}>
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                {t("Side Navbar.Logout")}
              </li>
            )}
          </li>
        </ul>
        <div className="nav_lang_translater">
        <Select
        className="my-select"
        classNamePrefix = "my-select"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              options={Language}
              maxMenuHeight={150}
              menuPlacement="auto"
              isSearchable={true}
              style={{ textAlign: "left", background:"red" }}
            />
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
