import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { withTranslation } from 'react-i18next';
import { updatePassword } from "./../services/authService";
import "./../designStyles/LoginForm.css";
import { showSuccessToast, showErrorToast } from "./utils/toastMessage";

class UpdatePassword extends Form {
  state = {
    isLoading: false,
    isDisabled: false,
    data: { email: "", newPassword: "", oldPassword: "", confirmPassword: "" },
    errors: {},
    languageLoaded: false
  };

  schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email ID"),
    oldPassword: Joi.string().required().label("Old Password"),
    newPassword: Joi.string().min(5).required().label("New Password"),
    confirmPassword: Joi.string().min(5).required().label("Confirm Password"),
  };

  doSubmit = async () => {
    const { t } = this.props;
    try {
      //call the server
      const { data } = this.state;
      await updatePassword(data.email, data.oldPassword, data.newPassword, data.confirmPassword);
      showSuccessToast(t("UpdatePassword.Password updated successfully"));
      setTimeout(() => {
        window.location = "/login";
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        showErrorToast(t(`UpdatePassword.${ex.response.data.message}`)); 
      }
    }
  };

  validateEmail(email) {
    const re = /^[a-zA-Z0-9.\u0600-\u06FF_%+-]+@[a-zA-Z0-9.\u0600-\u06FF-]+\.[a-zA-Z\u0600-\u06FF]{2,}$/;
    return re.test(email);
}

  renderButton(label) {
    const { email, oldPassword, newPassword, confirmPassword } =
      this.state.data;
    const isDisabled =
      !email ||
      !this.validateEmail(email) ||
      !oldPassword ||
      !newPassword ||
      !confirmPassword ||
      this.state.errors?.newPassword ||
      newPassword !== confirmPassword;

    return (
      <button
        disabled={isDisabled}
        class="btn btn-primary mdk--button"
        type="submit"
      >
        {label}
      </button>
    );
  }

  changeLanguage = async (lng) => {
    const { i18n } = this.props;
    await i18n.changeLanguage(lng);
    this.setState({languageLoaded:true})
  };

  componentDidMount() {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    this.changeLanguage(selectedLanguage);
  }
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/* <ToastContainer /> */}
        <section id="login-page" className="login-page">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-6 lg_form_left">
                <div className="login-left-main">
                  <div className="login-overlay">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="heading-white">{t("UpdatePassword.Welcome back")}</h2>
                      </div>
                      <div className="col-sm-12">
                        <p>
                        {t("UpdatePassword.Show your true potential with Semantify. The easy to use and intelligent talent assessment, evaluation and management platform, offering an engaging and seamless test experience. Register and start your tests now.")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="right-login">
                  <div className="body-widget">
                    <h2 className="heading-black">{t("UpdatePassword.Update Password")}</h2>
                    <form onSubmit={this.handleSubmit}>
                      {this.renderInput("email", t("UpdatePassword.Email"))}
                      {this.renderPassword(
                        "oldPassword",
                        t("UpdatePassword.Old Password"),
                        "password"
                      )}
                      {this.renderPassword(
                        "newPassword",
                        t("UpdatePassword.New Password"),
                        "password"
                      )}
                      {this.renderPassword(
                        "confirmPassword",
                        t("UpdatePassword.Confirm Password"),
                        "password"
                      )}
                      {this.state.data.confirmPassword &&
                        this.state.data.newPassword !==
                          this.state.data.confirmPassword && (
                          <div className="alert alert-danger">
                            {t("UpdatePassword.Confirm Password does not match with New Password")}
                          </div>
                        )}
                      {this.renderButton(t("UpdatePassword.Submit"))}
                    </form>
                    <div className="flexBox">
                    <p>
                      {t("Register.Already have an account?")}
                      <a href="/login"> {t("Register.Log In")} </a>{" "}
                    </p>
                    <a href="/reset" style={{ float: "right" }}>
                      {t("LogIn.Forgot Password")}
                    </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-main">
              <img src="images/logo.png" alt="" />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(UpdatePassword);
