import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";

import { getModules } from "../../services/moduleService";
import {
  getAssessment,
  saveAssessment,
} from "../../services/assessmentService";
import MultipleInputForm from "./MultipleInputForm_3";
import { withTranslation } from 'react-i18next';
import "../../designStyles/AssessmentEntryForm.css";
import { showErrorToast, showSuccessToast } from "../utils/toastMessage";

class AssessmentForm extends Form {
  state = {
    data: {
      title: "",
      moduleId: "",
    },
    modules: [],
    errors: {},
    onSubmitData: false,
  };

  schema = {
    _id: Joi.string(),
    title: Joi.string().required().label("Title"),
    moduleId: Joi.string().required().label("Module"),
    questionSet: Joi.required(),
  };

  async populateModules() {
    const { data: modules } = await getModules();
    this.setState({ modules });
  }
  async populateAssessments() {
    try {
      const assessmentId = this.props.match.params.id;
      if (assessmentId === "new") return;

      const { data: assessment } = await getAssessment(assessmentId);
      this.setState({ data: this.mapToViewModel(assessment) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not found");
    }
  }

  async componentDidMount() {
    await this.populateModules();
  }

  mapToViewModel(assessment) {
    return {
      _id: assessment._id,
      title: assessment.title,
      moduleId: assessment.modules._id,
    };
  }

  getAllQuestionSet(qSetChildData) {
    const { t } = this.props
    const qData = qSetChildData;
    const { length: count } = qData;

    for (let i = 0; i < count; i++) {
      if (!!qData[i].question && !!qData[i].qsId && !!qData[i].answer) {
        this.setState({ onSubmitData: true });
        this.setState({ data: { ...this.state.data, questionSet: qData } });
      } else {
        showErrorToast(t("allAssignmentMCQ.Please fill all the fields"))
        this.setState({ onSubmitData: false });
      }
    }
  }

  doSubmit = async () => {
    const { t } = this.props
    try {
      const { data } = this.state;
      await saveAssessment(data.title, data.moduleId, data.questionSet);
      this.props.history.push("/allAssessments");
      showSuccessToast(t("allAssignmentMCQ.Assessment Created Successfully"));
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
        showErrorToast(t("allAssignmentMCQ.Something Went Wrong"));
      }
    }
  };
  render() {
    const { t } = this.props
    const createQuestionSet = (
      <MultipleInputForm
        receiveDataCallback={this.getAllQuestionSet.bind(this)}
      />
    );
    return (
      <div className="col-md-12">
        <h1 className="create-title">
          {t("allAssignmentMCQ.Create")} <span> {t("allAssignmentMCQ.Assessment")}</span>  {t("allAssignmentMCQ.Form")}
        </h1>
        <div className="mdk-divider"></div>

        <form onSubmit={this.handleSubmit}>
          <div className="row mb-2">
            <div className="col-sm-12">
              <div className="form-white">
                <div className="row">
                  <div className="col-lg-6 form-group"> 
                    {this.renderInput("title",  t("allAssignmentMCQ.Title"), "", "", "mcqForm")} 
                  </div>
                  <div className="col-lg-6 form-group">
                    {this.renderSelect("moduleId", t("allAssignmentMCQ.Module"), this.state.modules, "mcqForm")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-white2">{createQuestionSet}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mcqFormSave" disabled={this.state.onSubmitData} >
            {this.renderButton(t("assignTest.Save"))}
            </div>
          </div>
        </form>
        <br />
      </div>
    );
  }
}

export default withTranslation()(AssessmentForm);
