// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_inputArea_div__16Rlc {\n  /* background-color: rgb(32, 31, 31); */\n  margin: auto;\n  margin-top: 30px;\n  font-size: 2rem;\n  font-weight: 400;\n  width: 100%;\n  min-height: 70px;\n  height: 100%;\n  border-radius: 10px;\n  padding: 0 10px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  background: #fff!important;\n  box-shadow: 0 9px 24px rgb(0 0 0 / 12%), 0 9px 24px rgb(0 0 0 / 12%)!important;\n  border: none!important;\n  border-radius: 0.5rem!important;\n  padding: 1em 0 1em 15px!important;\n  font-family: 'Ibarra Real Nova', serif;\n  color: green!important;\n} \n\n/* .inputArea_div {\n  width: 100%!important;\n  margin-top: 0!important;\n  min-height: 100px!important;\n  color: green!important;\n  font-size: 20px!important;\n} */\n\n\n/* .inputArea {\n  width: 100%!important;\n  margin-top: 0!important;\n  min-height: 100px!important;\n  color: green!important;\n  font-size: 20px!important;\n}\n.last_section {\n  background: #fff!important;\n  box-shadow: 0 9px 24px rgb(0 0 0 / 12%), 0 9px 24px rgb(0 0 0 / 12%)!important;\n  border: none!important;\n  border-radius: 0.5rem!important;\n  font-size: 1.8rem!important;\n  padding: 1em 0 1em 15px!important;\n  font-family: 'Ibarra Real Nova', serif;\n  color: #000!important;\n} */\n\n.style_container__2mc9u > div {\n  border: 1px solid red;\n}", ""]);
// Exports
exports.locals = {
	"inputArea_div": "style_inputArea_div__16Rlc",
	"container": "style_container__2mc9u"
};
module.exports = exports;
