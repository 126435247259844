// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_main__3XYTX {\n  width: 100%;\n  height: -moz-fit-content;\n  height: fit-content;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  padding: 20px 0;\n}\n.style_main__3XYTX > div {\n  margin: 0 15px 0 0;\n}\n.style_main__3XYTX span {\n  display: block;\n  text-align: center;\n  font-weight: 400;\n  font-size: .875rem;\n  font-weight: bold;\n  font-size: 14px;\n  margin: 0 0 0 0;\n}\n.style_main__3XYTX span:first-child {\n  font-weight: bold;\n  width: 6rem;\n  height: 6rem;\n  line-height: 60px;\n  border-radius: 1.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #fff;\n  font-size: 38px;\n  margin: 0 0 5px 0;\n  font-family: 'Oswald', sans-serif;\n}\n\n.style_main__3XYTX > div:nth-child(5) span:first-child {\n  border: 4px solid #5266bb!important;\n  margin: 0 0 0 50px;\n  border-radius: 50%;\n}\n.style_main__3XYTX > div:nth-child(5) span:last-child {\n  margin: 5px 0 0 50px;\n  font-weight: bold;\n  font-size: 18px;\n}\n\n\n\n\n", ""]);
// Exports
exports.locals = {
	"main": "style_main__3XYTX"
};
module.exports = exports;
