import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import SelectDropDown from "./selectDrop";
import "./../../designStyles/AllForms.css";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };


  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };


  renderButton(label) {
    return (
      <button
        disabled={this.validate()}
        className="btn btn-primary mdk--button"
      >
        {label}
      </button>
    );
  }

  renderSelect(name, label, options, mcqForm) {
    const { data, errors } = this.state;
    return (
      <SelectDropDown
        name={name}
        value={data[name]}
        label={
          <>
            {label}{" "}
            {mcqForm == "mcqForm" && <sup className="mandatory_field">*</sup>}
          </>
        }
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderhandleAddInput(label) {
    return <button className="btn btn-primary mdk--button">{label}</button>;
  }

  renderhandleRemoveInput(label) {
    return <button className="btn btn-primary mdk--button">{label}</button>;
  }


  renderInput(name, label, userUuid, type = "text", mcqForm) {
    const { data, errors } = this.state;
    const { t } = this.props
    let err;

    function isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    if(errors[name]?.includes('"Email Id" is not allowed')){
      err = errors[name] && t(`LogIn.${errors[name]?.replace(/"Email ID"/g, 'Email ID')}`)
    } else if (errors[name]?.includes('"Username" is not allowed to be empty')){
      err = errors[name] && t(`Register.${errors[name]?.replace(/"Username"/g, 'Username')}`)
    } else if (errors[name]?.includes('"Email" length must be at least 5 characters long')){
      err = errors[name] && t(`Register.${errors[name]?.replace(/"Email"/g, 'Email')}`)
    } else if (errors[name]?.includes('"Email" must be a valid email')){
      err = errors[name] && t(`Register.${errors[name]?.replace(/"Email"/g, 'Email')}`)
    } else if (errors[name]?.includes('"Email ID" must be a valid email')){
      err = errors[name] && t(`LogIn.${errors[name]?.replace(/"Email ID"/g, 'Email ID')}`)
    } else if (errors[name]?.includes('"Email ID" is not allowed to be empty')){
      err = errors[name] && t(`LogIn.${errors[name]?.replace(/"Email ID"/g, 'Email ID')}`)
    } else if (errors[name]?.includes('User already registered.')){
      err = errors[name] && t(`LogIn.${errors[name]}`)
    } else if (errors[name]?.includes('"Username" length must be at least 5 characters long')){
      err = errors[name] && t(`LogIn.${errors[name]?.replace(/"Username"/g, 'Username')}`)
    } else if (errors[name]?.includes('"Email" is not allowed to be empty')){
      err = errors[name] && t(`LogIn.${errors[name]?.replace(/"Email"/g, 'Email')}`)
    } else if(name === "email"){
      const email = isValidEmail(data[name])
      if(!email){
        err = data[name] && t(`Register.Email must be a valid email`)
      }
    }
    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={
          <>
            {label}{" "}
            {mcqForm == "mcqForm" && <sup className="mandatory_field">*</sup>}
          </>
        }
        onChange={this.handleChange}
        error={err}
        inputRef={name === "username" ? this.usernameRef : null}
        disabled={userUuid ? true : false}
      />
    );
  }

   handleChangePassword = (e, label) => {
    const { name, value } = e.target;
    const { t } = this.props
    let error = null;
    if (name === "newPassword" || label=="Password*" || label === "Mot de passe*" || label === "كلمة المرور الجديدة" || label === "كلمة المرور*" ) {
      let passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])/;
      if (label === "كلمة المرور الجديدة" || label === "كلمة المرور*" ) {
        passwordRegex = /^(?=.*[0-9\u0660-\u0669])/;
      }
      if (value.length < 8) {
        error = `${name === "newPassword" ? t("Register.New Password") : t("Register.Password")} ${t("Register.should be at least 8 characters long")}`;
      } else if (!passwordRegex.test(value)) {
        error = `${name === "newPassword" ? t("Register.New Password") : t("Register.Password")} ${t("Register.should contain at least one capital letter and one digit")}`;
      }
    }
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [name]: value
      },
      errors: {
        ...prevState.errors,
        [name]: error
      }
    }));
  };
  
  renderPassword(name, label, type = "text") {
    const { data, errors } = this.state;
    const showPassword = this.state[`showPassword_${name}`] || false;
  
    const handleTogglePassword = () => {
      this.setState((prevState) => ({
        [`showPassword_${name}`]: !prevState[`showPassword_${name}`]
      }));
    };
    
    
    return (
      <div className="password_lgn Login_Form">
        <label htmlFor={name}>{label}</label>
        <div className="password-input">
          <Input
            type={showPassword ? "text" : "password"}
            name={name}
            value={data[name]}
            onChange={(event) => this.handleChangePassword(event, label)}
            error={errors[name]}
          />
          <button
            type="button"
            onClick={handleTogglePassword}
            className="password-toggle"
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </button>
        </div>
      </div>
    );
  }
  
  
}

export default (Form);