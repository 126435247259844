import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import jwtDecode from "jwt-decode";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import Loadable from "react-loadable";

import { hotjar } from "react-hotjar";
import * as amplitude from "@amplitude/analytics-browser";
// import { track, identify, setGroup, groupIdentify, Identify } from '@amplitude/analytics-browser';

// import { env } from 'process';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./../designStyles/Home_Page_Files/css/custom_v2.css";
import "./../designStyles/Home_Page_Files/css/stellarnav.css";
import "./../designStyles/Home_Page_Files/css/animate.css";
import "./../designStyles/customTheme.css";
import "./../designStyles/Home_Page_Files/css/mobile-responsive.css";

import { getOneUser } from "../services/userService";

/**Components Import */

// import ProtectedRoutes from "./common/protectedRoute";
import NavBar from "./NavBar_v2";
import LoginForm from "./LoginForm_v2";
import ForgetPass from "./ForgetPass";
import UpdatePassword from "./ForceUpdatePass_v2";
import RegisterForm from "./SignUpForm_v2";
import Logout from "./Logout";
import UserProfileCustom from "./UserProfileCustom";

import AssessmentModuleTypeMain from "./AssessmentModuleTypeMain_v1";

import AssessmentForm from "./allMCQAssessmentTypeFiles/AssessmentForm";
import ModuleForm from "./allMCQAssessmentTypeFiles/ModuleForm";
import DataEntryModuleMain from "./allDEntryAssessmentTypeFiles/DataEntryMain";
import DataEntryPanel_q1 from "./allDEntryAssessmentTypeFiles/DataEntryPanel_q1";
import DataEntryPanel_q2 from "./allDEntryAssessmentTypeFiles/DataEntryPanel_q2";
import DataEntryPanel_q3 from "./allDEntryAssessmentTypeFiles/DataEntryPanel_q3";

import TypingAssessment from "./allTypingAssessmentTypeFiles_v2/TypingMain";

import WritingModuleMain from "./allWritingAssessmentTypeFiles/writingModuleMain";
import SpeechAssessmentMain from "./allSpeechAssessmentTypeFiles/SpeechAssessmentMain";
import SpeechPanel from "./allSpeechAssessmentTypeFiles/SpeechAnalysisPanel_v2";

import ExportAnalytics from "./CustomAnalytics_v1";
import { withTranslation } from 'react-i18next';

import UserContext from "./commonContext/userContext";

import "./App.css";
import DataEntryPanel_q4 from "./allDEntryAssessmentTypeFiles/DataEntryPanel_q4";
import DataEntryPanel_q5 from "./allDEntryAssessmentTypeFiles/DataEntryPanel_q5";

function Loading({ error }) {
  if (error) {
    return "Oh-noes..Something wrong happened!";
  } else {
    return <h3>Loading...</h3>;
  }
}

const HomePageMn = Loadable({
  loader: () => import("./HomePageMn"),
  loading: Loading,
});

const Header = Loadable({
  loader: () => import("./Header"),
  loading: Loading,
});

const DashboardMain = Loadable({
  loader: () => import("./DashboardMain"),
  loading: Loading,
});

const AssessmentPanel = Loadable({
  loader: () => import("./allMCQAssessmentTypeFiles/AssessmentPanelMn"),
  loading: Loading,
});

const AssessmentViewOne = Loadable({
  loader: () => import("./allMCQAssessmentTypeFiles/AllAssessmentsDb"),
  loading: Loading,
});

const Performance = Loadable({
  loader: () => import("./Performance_v5"),
  loading: Loading,
});

//Main App Component

class App extends Component {
  state = {
    selectedLanguage: localStorage.getItem("selectedLanguage"),
    isOpen: false,
  };
  appInitiation = async () => {
    try {
      const jwt = localStorage.getItem("token");
      const user_imp = jwtDecode(jwt);
      const { data: user } = await getOneUser(user_imp._id);
      this.setState({ user });
      this.setState({ user_imp: user_imp });
      // console.log(user);
      if (process.env.NODE_ENV === "production") {
        console.log(process.env.NODE_ENV);
        hotjar.initialize(
          process.env.REACT_APP_HOTJAR_KEY,
          process.env.REACT_APP_HOTJAR_VER
        );
        if (hotjar.initialized()) {
          hotjar.identify("USER_ID", { userProperty: user_imp._id });
        }

        amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, user_imp._id, {
          defaultTracking: {
            sessions: true,
            pageViews: true,
            formInteractions: true,
            fileDownloads: true,
          },
        });

        const event = new amplitude.Identify();
        event.set("User_ID", user_imp._id);
      }
    } catch (ex) {
      // console.log("No active user found")
    }
  };

  handleLanguageChange = async (lng) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng?.value);
    this.setState({ selectedLanguage: lng });
    localStorage.setItem("selectedLanguage", lng?.value);
  };

  componentDidMount() {
    this.appInitiation();
    const storage = localStorage.getItem("selectedLanguage");
    this.handleLanguageChange({
      id: storage === "ar" ? 3 : storage === "fr" ? 2 : 1,
      value: localStorage.getItem("selectedLanguage"),
      label:
        storage === "ar" ? "عربي" : storage === "fr" ? "Français" : "English",
    });
  }
  render() {
    const { user } = this.state;
    const { i18n } = this.props
    return (
      <React.Fragment>
        <ToastContainer />
        <UserContext.Provider
          value={{
            currentUserInfo: user,
          }}
        >
          <div className={(i18n.language === "ar") ? 'App rtl' : 'App'} >
            {/* {user && <IdleLogout />} */}

            <section id="mdk-main-part" className="mdk-main-part">
              <div className="container-fluid">
                {!user && (
                  <div className="">
                    <Switch>
                      <Route exact path="/" component={HomePageMn} />
                      <Route
                        exact
                        path="/registration"
                        component={RegisterForm}
                      />
                      <Route exact path="/login" component={LoginForm} />

                      <Route
                        exact
                        path="/reset"
                        render={(props) => {
                          return (
                            <ForgetPass {...props} user={this.state.user} />
                          );
                        }}
                      />

                      <Route
                        exact
                        path="/updatePass"
                        render={(props) => {
                          return (
                            <UpdatePassword />
                          );
                        }}
                      />
                    </Switch>
                  </div>
                )}
                <div className="grid-main">
                  {user && <div> {<NavBar user={user} />}</div>}
                  {user && (
                    <div className="right-panel-new">
                      {user && (
                        <div className="heading">
                          {/*Header*/}
                          <Header />
                        </div>
                      )}
                      <div className="main-white">
                        <div className="main-content">
                          {user && (
                            <div className="mdk-right-part">
                              
                              <Switch>
                                {user ? (
                                  <Redirect from="/login" to="/allAssessmentTypes" />
                                ) : (
                                  <Route
                                    exact
                                    path="/login"
                                    component={LoginForm}
                                  />
                                )}
                                <Route
                                  exact
                                  path="/allAssessmentTypes"
                                  render={(props) => {
                                    if (!user) return <Redirect to="/login" />;
                                    return (
                                      <AssessmentModuleTypeMain
                                        {...props}
                                        user={this.state.user}
                                      />
                                    );
                                  }}
                                />
                                {user && <Route
                                  exact
                                  path="/"
                                  render={(props) => {
                                    if (!user) return <Redirect to="/login" />;
                                    return (
                                      <AssessmentModuleTypeMain
                                        {...props}
                                        user={this.state.user}
                                      />
                                    );
                                  }}
                                />}
                                <Route
                                  exact
                                  path="/allAssessments"
                                  render={(props) => {
                                    if (!user) return <Redirect to="/login" />;
                                    return (
                                      <AssessmentViewOne
                                        {...props}
                                        user={this.state.user}
                                      />
                                    );
                                  }}
                                />
                                {/* <ProtectedRoutes path="/allAssessments" component={AssessmentViewOne} /> */}
                                <Route
                                  exact
                                  path="/allAssessments/new"
                                  component={AssessmentForm}
                                />
                                <Route
                                  exact
                                  path="/allModules/new"
                                  component={ModuleForm}
                                />
                                <Route
                                  path="/allAssessments/:id"
                                  component={AssessmentPanel}
                                />

                                {user.assignedDEAssessments && (
                                  <Route
                                    exact
                                    path="/dataEntryAssessment"
                                    component={DataEntryModuleMain}
                                  />
                                )}
                                {user.assignedDEAssessments && (
                                  <Route
                                    exact
                                    path="/dataEntryAssessment/q1"
                                    component={DataEntryPanel_q1}
                                  />
                                )}
                                {user.assignedDEAssessments && (
                                  <Route
                                    exact
                                    path="/dataEntryAssessment/q2"
                                    component={DataEntryPanel_q2}
                                  />
                                )}
                                {user.assignedDEAssessments && (
                                  <Route
                                    exact
                                    path="/dataEntryAssessment/q3"
                                    component={DataEntryPanel_q3}
                                  />
                                )}
                                {user.assignedDEAssessments && (
                                  <Route
                                    exact
                                    path="/dataEntryAssessment/q4"
                                    component={DataEntryPanel_q4}
                                  />
                                )}
                                {user.assignedDEAssessments && (
                                  <Route
                                    exact
                                    path="/dataEntryAssessment/q5"
                                    component={DataEntryPanel_q5}
                                  />
                                )}
                                {user.assignedTSAssessments && (
                                  <Route
                                    exact
                                    path="/typingSpeedTest"
                                    component={TypingAssessment}
                                  />
                                )}
                                {user.assignedEWAssessments && (
                                  <Route
                                    exact
                                    path="/articleWritingAssessment"
                                    component={WritingModuleMain}
                                  />
                                )}
                                {user.assignedSAAssessments && (
                                  <Route
                                    exact
                                    path="/speechVoiceAssessment"
                                    component={SpeechAssessmentMain}
                                  />
                                )}
                                {user.assignedSAAssessments && (
                                  <Route
                                    exact
                                    path="/speechVoiceAssessment/seen"
                                    component={SpeechPanel}
                                  />
                                )}
                                <Route
                                  exact
                                  path="/mainDashboard"
                                  component={DashboardMain}
                                />
                                <Route
                                  exact
                                  path="/advancedAnalytics"
                                  component={ExportAnalytics}
                                />
                                <Route
                                  exact
                                  path="/userScore/:id"
                                  component={Performance}
                                />
                                <Route
                                  exact
                                  path="/userProfileCustomUpdate/:id"
                                  component={UserProfileCustom}
                                />
                                <Route
                                  exact
                                  path="/logout"
                                  component={Logout}
                                />
                              </Switch>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </UserContext.Provider>
      </React.Fragment>
    );
  }
}

export default withTranslation()(App);
