import React, { useState, useRef, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import styles from "./app.module.css";
import "../../designStyles/TypingTestMain/typingTestStyle.css";
import { quotesArray, random, allowedKeys, quotesArrayInFrench, quotesArrayInArabic } from "./util/helperFunctions";

import InputArea from "./InputArea";
import ShowText from "./ShowText";
import Statistics from "./Statistics";

import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { submitTypeSpeedScore } from "../../services/scoreService/scoreTSService_v2";
import { getCurrentUser } from "../../services/authService";
import { showErrorToast, showSuccessToast } from "../utils/toastMessage";
import { useTranslation } from 'react-i18next';


const TypingAssessment = () => {
  const inputRef = useRef(null);
  const outputRef = useRef(null);
  const [duration, setDuration] = useState(60);
  const [started, setStarted] = useState(false);
  const [ended, setEnded] = useState(false);
  const [index, setIndex] = useState(0);
  const [correctIndex, setCorrectIndex] = useState(0);
  const [errorIndex, setErrorIndex] = useState(0);
  const [quote, setQuote] = useState({});
  const [input, setInput] = useState("");
  const [cpm, setCpm] = useState(0);
  const [wpm, setWpm] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [isError, setIsError] = useState(false);
  const [userId, setUserId] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitDataFlag, setSubmitDataFlag] = useState(false);
  const { t, i18n } = useTranslation();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  let interval = null;
 const stopBrowserPopup = useRef(false)

  useEffect(() => {
    const newQuote = random(i18n.language === "fr" ? quotesArrayInFrench : i18n.language === "ar" ? quotesArrayInArabic : quotesArray);
    setQuote(newQuote);
    setInput(newQuote.quote);
    try {
      const user = getCurrentUser();
      setUserId(user._id);
    } catch (ex) {
    }
  }, [i18n.language]);

  useEffect(() => {
    if (ended) {
      handleSubmit();
    }
    return () => {
      clearInterval(interval);
    };
  }, [ended]);

  const handleEnd = async () => {
    setEnded(true);
    setStarted(false);
    clearInterval(interval);
  };

  const setTimer = () => {
    const now = Date.now();
    const seconds = now + duration * 1000;
    interval = setInterval(() => {
      const secondLeft = Math.round((seconds - Date.now()) / 1000);
      setDuration(secondLeft);
      if (secondLeft === 0) {
        handleEnd();
      }
    }, 1000);
  };

  const handleStart = () => {
    setStarted(true);
    setEnded(false);
    setInput(quote.quote);
    inputRef.current.focus();
    setTimer();
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    const { key } = e;
    
    const quoteText = quote.quote;
    if (key === quoteText.charAt(index)) {
      setIndex(index + 1);
      const currenChar = quoteText.substring(
        index + 1,
        index + quoteText.length
      );
      setInput(currenChar);
      setCorrectIndex(correctIndex + 1);
      setIsError(false);
      outputRef.current.innerHTML += key;
    } else {
      if (allowedKeys.includes(key)) {
        setErrorIndex(errorIndex + 1);
        setIsError(true);
        outputRef.current.innerHTML += `<span class="text-danger">${key}</span>`;
      }
    }

    const elapsedTime = (60 - duration) / 60;
    const _accuracy = index === 0 ? 0  : Math.max(0, Math.floor(((index - errorIndex) / index) * 100));
    const _wpm = elapsedTime === 0 ? 0 : Math.round(correctIndex / 5 / elapsedTime);
    setAccuracy(_accuracy);
    setCpm(correctIndex);
    setWpm(_wpm);

    if (index + 1 === quoteText.length || errorIndex > 50) {
      if(errorIndex > 50){
        showErrorToast("Error threshold exceeded");
      }
      handleEnd();
    }
  };

  const handleBackConfirm = (e) => {
    if (!submitDataFlag && !stopBrowserPopup.current) {
      e.preventDefault();
      let event = window.confirm(t("common.Changes that you may not be saved."));
      if (event) {
        window.history.back();
      } else {
        window.history.pushState(null, "", window.location.href);
      }
    }
  };

  const handleConfirm = (e) => {
    if (!submitDataFlag && !stopBrowserPopup.current) {
      var confirmationMessage = t("common.Do you want to leave this page.");
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };

  const handleSubmit = async () => {
    setIsDisabled(true);
    setIsLoading(true);
    stopBrowserPopup.current = true
    try {
      const response = await submitTypeSpeedScore(
        userId,
        cpm,
        wpm,
        accuracy,
        errorIndex
      );
      window.removeEventListener("beforeunload", handleConfirm);
      window.removeEventListener("popstate", handleBackConfirm);
      showSuccessToast(t(`ListeningAssessmentQ4.${response.data.message}`));
      await sleep(3000);
      window.location = "/allAssessmentTypes";
    } catch (error) {}
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleConfirm);
    window.addEventListener("popstate", handleBackConfirm);
    window.history.pushState(null, "", window.location.href);
    const language = localStorage.getItem("selectedLanguage")
    i18n.changeLanguage(language);
    return () => {
      window.removeEventListener("beforeunload", handleConfirm);
      window.removeEventListener("popstate", handleBackConfirm);
    };
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        inputRef.current.focus();
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  
  return (
    <div>
      {/* <Header /> */}
      <Statistics
        cpm={cpm}
        wpm={wpm}
        duration={duration}
        accuracy={accuracy}
        errorIndex={errorIndex}
      />

      {/* Start Button */}
      {ended || started ? (
        <button
          onClick={handleSubmit}
          className={styles.startButton}
          style={{
            backgroundColor: "#ff8a65",
            border: "5px solid #e64a19",
          }}
          variant="dark"
          disabled={isDisabled}
        >
          {" "}
          {isLoading && (
            <Spinner
              as="span"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
              animation="border"
            />
          )}
          <span> {isLoading ? t('TypingTest.Submitting...') : t('TypingTest.Submit')}</span>{" "}
        </button>
      ) : (
        <div
          onClick={handleStart}
          className={styles.startButton}
          style={{
            backgroundColor: "#8bc34a",
            border: "5px solid #33691e",
          }}
        >
          {" "}
          <span> {t('TypingTest.Start')} </span>{" "}
        </div>
      )}
      {/* Start Button end */}

      <div className={styles.container}>
        <div className={styles.leftSideInContainer}></div>
        <div className={styles.rightSideInContainer}>
          <ShowText
            quote={quote}
            started={started}
            ended={ended}
            isError={isError}
            inputRef={inputRef}
            input={input}
            handleKeyDown={handleKeyDown}
          />

          <InputArea value={outputRef} />
        </div>
      </div>
    </div>
  );
};

export default TypingAssessment;
