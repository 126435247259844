import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import Select from "react-select";

import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { CSVLink } from "react-csv";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { location_filter, location_filter_In_Arabic, location_filter_In_French } from "../bank/PredifinedUserLocations";
import "./../designStyles/SketchDashb.css";
import "./../designStyles/Analytics_v1.css";
import {
  getConsolidatedReport,
  getMcqAssessmentReport,
  getListeningAssessmentReport,
  getTypeAssessmentReport,
  getArticleAssessmentReport,
  getSpeechAssessmentReport,
} from "../services/analyticsService";
import { getAllUserAssignmentProfile } from "../services/userAssignmentProfileService";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";
import 'moment/locale/fr';
import 'moment/locale/ar';
const moment = extendMoment(originalMoment);
moment.locale('fr'); 
class ExportAnalytics extends React.Component {
  constructor(props, context) {
    super(props, context);

    const today = moment();

    this.state = {
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      value1: moment.range(today.clone().subtract(6, 'days').startOf('day'), today.endOf('day')),
      value2: moment.range(today.clone().subtract(6, 'days').startOf('day'), today.endOf('day')),
      value3: moment.range(today.clone().subtract(6, 'days').startOf('day'), today.endOf('day')),
      customEnd1: today.clone().add(1, "days"),
      customEnd2: today.clone().add(1, "days"),
      customEnd3: today.clone().add(1, "days"),
      consolidatedData: [],
      assignmentProfileData: [],
      mcqAssessmentData: [],
      listeningAssessmentData: [],
      typeSpeedAssessmentData: [],
      articleAssessmentData: [],
      speechAssessmentData: [],
      allLocations: [],
      uLocation: "",
      language: i18n.language
    };

    this.csvLinkEl = React.createRef();
    this.mcqAssessmentLink = React.createRef();
    this.listeningAssessmentLink = React.createRef();
    this.typeSpeedAssessmentLink = React.createRef();
    this.articleAssessmentLink = React.createRef();
    this.speechAssessmentLink = React.createRef();
    this.userAssignmentProfileLink = React.createRef();

    this.headersUserDetails = [];

    this.headersUserAssignmentProfile = [
      { label: "CandidateId", key: "candidateId" },
      { label: "CandidateEmail", key: "candidateEmail" },
      { label: "CandidateLocation", key: "candidateLocation" },
      { label: "CandidateRegistrationTime", key: "candidateRegistrationTime" },
      { label: "RecruiterId", key: "recruiterId" },
      { label: "RecruiterEmail", key: "recruiterEmail" },
      { label: "RecruiterLocation", key: "recruiterLocation" },
      { label: "AssessmentAssignedAt", key: "assessmentAssignedAt" },
      { label: "MCQModule", key: "mcqModule" },
      { label: "ListeningModule", key: "listeningModule" },
      { label: "TypingModule", key: "typingModule" },
      { label: "ArticleModule", key: "articleModule" },
      { label: "SpeechModule", key: "speechModule" },
      { label: "isArchived", key: "isArchived" },
      { label: "isAdmin", key: "isAdmin" },
      { label: "RecruitmentStatus", key: "recruitmentStatus" },
    ];

    this.headersUserAssignmentProfileInFrench = [
      { label: "Identifiant du candidat", key: "candidateId" },
      { label: "Email du candidat", key: "candidateEmail" },
      { label: "Lieu du candidat", key: "candidateLocation" },
      { label: "Heure d'inscription du candidat", key: "candidateRegistrationTime" },
      { label: "Identifiant du recruteur", key: "recruiterId" },
      { label: "Email du recruteur", key: "recruiterEmail" },
      { label: "Lieu du recruteur", key: "recruiterLocation" },
      { label: "Assigné à l'évaluation à", key: "assessmentAssignedAt" },
      { label: "Module MCQ", key: "mcqModule" },
      { label: "Module d'écoute", key: "listeningModule" },
      { label: "Module de frappe", key: "typingModule" },
      { label: "Module d'article", key: "articleModule" },
      { label: "Module de discours", key: "speechModule" },
      { label: "Est archivé", key: "isArchived" },
      { label: "Est administrateur", key: "isAdmin" },
      { label: "Statut de recrutement", key: "recruitmentStatus" },
    ];

    this.headersUserAssignmentProfileInArabic = [
      { label: "معرف المرشح", key: "candidateId" },
      { label: "بريد المرشح الإلكتروني", key: "candidateEmail" },
      { label: "موقع المرشح", key: "candidateLocation" },
      { label: "وقت تسجيل المرشح", key: "candidateRegistrationTime" },
      { label: "معرف المجند", key: "recruiterId" },
      { label: "بريد المجند الإلكتروني", key: "recruiterEmail" },
      { label: "موقع المجند", key: "recruiterLocation" },
      { label: "تاريخ تعيين التقييم", key: "assessmentAssignedAt" },
      { label: "وحدة الأسئلة المتعددة", key: "mcqModule" },
      { label: "وحدة الاستماع", key: "listeningModule" },
      { label: "وحدة الكتابة", key: "typingModule" },
      { label: "وحدة المقال", key: "articleModule" },
      { label: "وحدة الخطاب", key: "speechModule" },
      { label: "محفوظ", key: "isArchived" },
      { label: "مشرف", key: "isAdmin" },
      { label: "حالة التوظيف", key: "recruitmentStatus" },
    ];
    
    this.headersConsolidated = [
      { label: "UserId", key: "userId" },
      { label: "UserEmail", key: "email" },
      { label: "Location", key: "location" },
      { label: "RegistrationTime", key: "registrationTime" },
      { label: "McqAttemptsCount", key: "noMcqAttempt" },
      { label: "ListeningAttemptsCount", key: "noListeningAttempt" },
      { label: "TypingAttemptsCount", key: "noTypingAttempt" },
      { label: "ArticleAttemptsCount", key: "noArticleAttempt" },
      { label: "SpeechAttemptsCount", key: "noSpeechAttempt" },
      { label: "AvgMcq", key: "avgMcq" },
      { label: "AvgListen", key: "avgListen" },
      { label: "AvgTyping", key: "avgTyping" },
      { label: "AvgArticle", key: "avgArticle" },
      { label: "AvgSpeech", key: "avgSpeech" },
    ];

    this.headersConsolidatedInFrench = [
      { label: "Identifiant de l'utilisateur", key: "userId" },
      { label: "Email de l'utilisateur", key: "email" },
      { label: "Lieu", key: "location" },
      { label: "Heure d'inscription", key: "registrationTime" },
      { label: "Nombre de tentatives MCQ", key: "noMcqAttempt" },
      { label: "Nombre de tentatives d'écoute", key: "noListeningAttempt" },
      { label: "Nombre de tentatives de frappe", key: "noTypingAttempt" },
      { label: "Nombre de tentatives d'article", key: "noArticleAttempt" },
      { label: "Nombre de tentatives de discours", key: "noSpeechAttempt" },
      { label: "Moyenne MCQ", key: "avgMcq" },
      { label: "Moyenne d'écoute", key: "avgListen" },
      { label: "Moyenne de frappe", key: "avgTyping" },
      { label: "Moyenne d'article", key: "avgArticle" },
      { label: "Moyenne de discours", key: "avgSpeech" },
    ];

    this.headersConsolidatedInArabic = [
      { label: "معرف المستخدم", key: "userId" },
      { label: "بريد المستخدم الإلكتروني", key: "email" },
      { label: "الموقع", key: "location" },
      { label: "وقت التسجيل", key: "registrationTime" },
      { label: "عدد محاولات الاختبارات النظرية", key: "noMcqAttempt" },
      { label: "عدد محاولات الاستماع", key: "noListeningAttempt" },
      { label: "عدد محاولات الكتابة", key: "noTypingAttempt" },
      { label: "عدد محاولات المقالات", key: "noArticleAttempt" },
      { label: "عدد محاولات الخطاب", key: "noSpeechAttempt" },
      { label: "متوسط الاختبارات النظرية", key: "avgMcq" },
      { label: "متوسط الاستماع", key: "avgListen" },
      { label: "متوسط الكتابة", key: "avgTyping" },
      { label: "متوسط المقالات", key: "avgArticle" },
      { label: "متوسط الخطاب", key: "avgSpeech" },
    ];
    
    this.headersMcqAssessment = [
      { label: "UserId", key: "userId" },
      { label: "UserEmail", key: "userEmail" },
      { label: "UserLocation", key: "userLocation" },
      { label: "UserRegistrationTime", key: "registrationTime" },
      { label: "ScoreObtained", key: "score1" },
      { label: "TotalMarks", key: "totalMarks" },
      { label: "AssessmentId", key: "assessmentId" },
      { label: "AssessmentTitle", key: "assessmentTitle" },
      { label: "SubmitTime", key: "submitTime" },
      { label: "AssignedBy", key: "assignedBy" },
      { label: "AssignedAt", key: "assignedAt" },
    ];

    this.headersMcqAssessmentInFrench = [
      { label: "Identifiant de l'utilisateur", key: "userId" },
      { label: "Email de l'utilisateur", key: "userEmail" },
      { label: "Lieu de l'utilisateur", key: "userLocation" },
      { label: "Heure d'inscription de l'utilisateur", key: "registrationTime" },
      { label: "Score obtenu", key: "score1" },
      { label: "Nombre total de points", key: "totalMarks" },
      { label: "Identifiant de l'évaluation", key: "assessmentId" },
      { label: "Titre de l'évaluation", key: "assessmentTitle" },
      { label: "Heure de soumission", key: "submitTime" },
      { label: "Assigné par", key: "assignedBy" },
      { label: "Assigné à", key: "assignedAt" },
    ];

    this.headersMcqAssessmentInArabic = [
  { label: "معرف المستخدم", key: "userId" },
  { label: "بريد المستخدم الإلكتروني", key: "userEmail" },
  { label: "موقع المستخدم", key: "userLocation" },
  { label: "وقت تسجيل المستخدم", key: "registrationTime" },
  { label: "النتيجة المحصل عليها", key: "score1" },
  { label: "إجمالي الدرجات", key: "totalMarks" },
  { label: "معرف التقييم", key: "assessmentId" },
  { label: "عنوان التقييم", key: "assessmentTitle" },
  { label: "وقت التقديم", key: "submitTime" },
  { label: "المعين من قبل", key: "assignedBy" },
  { label: "التعيين في", key: "assignedAt" },
];
    
    this.headersListeningAssessment = [
      { label: "UserId", key: "userId" },
      { label: "UserEmail", key: "userEmail" },
      { label: "UserLocation", key: "userLocation" },
      { label: "UserRegistrationTime", key: "registrationTime" },
      { label: "ScoreObtained", key: "score1" },
      { label: "TotalMarks", key: "totalMarks" },
      { label: "AudioReplayCount", key: "replayCount" },
      { label: "SubmitTime", key: "submitTime" },
      { label: "AssignedBy", key: "assignedBy" },
      { label: "AssignedAt", key: "assignedAt" },
    ];

    this.headersListeningAssessmentInFrench = [
      { label: "Identifiant de l'utilisateur", key: "userId" },
      { label: "Email de l'utilisateur", key: "userEmail" },
      { label: "Lieu de l'utilisateur", key: "userLocation" },
      { label: "Heure d'inscription de l'utilisateur", key: "registrationTime" },
      { label: "Score obtenu", key: "score1" },
      { label: "Nombre total de points", key: "totalMarks" },
      { label: "Nombre de réécoutes audio", key: "replayCount" },
      { label: "Heure de soumission", key: "submitTime" },
      { label: "Assigné par", key: "assignedBy" },
      { label: "Assigné à", key: "assignedAt" },
    ];

    this.headersListeningAssessmentInArabic = [
      { label: "معرف المستخدم", key: "userId" },
      { label: "بريد المستخدم الإلكتروني", key: "userEmail" },
      { label: "موقع المستخدم", key: "userLocation" },
      { label: "وقت تسجيل المستخدم", key: "registrationTime" },
      { label: "النتيجة المحصل عليها", key: "score1" },
      { label: "إجمالي الدرجات", key: "totalMarks" },
      { label: "عدد إعادة تشغيل الصوت", key: "replayCount" },
      { label: "وقت التقديم", key: "submitTime" },
      { label: "المعين من قبل", key: "assignedBy" },
      { label: "التعيين في", key: "assignedAt" },
    ];

    this.headersTypeAssessment = [
      { label: "UserId", key: "userId" },
      { label: "UserLocation", key: "userLocation" },
      { label: "UserRegistrationTime", key: "registrationTime" },
      { label: "UserEmail", key: "userEmail" },
      { label: "WordPerMinuteScore", key: "score1wpm" },
      { label: "CharacterPerMinuteScore", key: "score2cpm" },
      { label: "AccuracyPercentage", key: "accuracy" },
      { label: "ErrorCount", key: "errorIndex" },
      { label: "SubmitTime", key: "submitTime" },
      { label: "AssignedBy", key: "assignedBy" },
      { label: "AssignedAt", key: "assignedAt" },
    ];

    this.headersTypeAssessmentInFrench = [
      { label: "Identifiant de l'utilisateur", key: "userId" },
      { label: "Lieu de l'utilisateur", key: "userLocation" },
      {
        label: "Heure d'inscription de l'utilisateur",
        key: "registrationTime",
      },
      { label: "Email de l'utilisateur", key: "userEmail" },
      { label: "Score de mots par minute", key: "score1wpm" },
      { label: "Score de caractères par minute", key: "score2cpm" },
      { label: "Pourcentage de précision", key: "accuracy" },
      { label: "Nombre d'erreurs", key: "errorIndex" },
      { label: "Heure de soumission", key: "submitTime" },
      { label: "Assigné par", key: "assignedBy" },
      { label: "Assigné à", key: "assignedAt" },
    ];

    this.headersTypeAssessmentInArabic = [
  { label: "معرف المستخدم", key: "userId" },
  { label: "موقع المستخدم", key: "userLocation" },
  { label: "وقت تسجيل المستخدم", key: "registrationTime" },
  { label: "بريد المستخدم الإلكتروني", key: "userEmail" },
  { label: "نقاط الكلمات في الدقيقة", key: "score1wpm" },
  { label: "نقاط الأحرف في الدقيقة", key: "score2cpm" },
  { label: "نسبة الدقة", key: "accuracy" },
  { label: "عدد الأخطاء", key: "errorIndex" },
  { label: "وقت التقديم", key: "submitTime" },
  { label: "المعين من قبل", key: "assignedBy" },
  { label: "التعيين في", key: "assignedAt" },
];

    this.headersArticleAssessment = [
      { label: "UserId", key: "userId" },
      { label: "UserEmail", key: "userEmail" },
      { label: "UserLocation", key: "userLocation" },
      { label: "UserRegistrationTime", key: "registrationTime" },
      { label: "ScoreObtained", key: "score1" },
      { label: "TotalMarks", key: "totalMarks" },
      { label: "QuestionSelected", key: "questionSelected" },
      { label: "TextAnswer", key: "answer" },
      { label: "SubmitTime", key: "submitTime" },
      { label: "AssignedBy", key: "assignedBy" },
      { label: "AssignedAt", key: "assignedAt" },
    ];

    this.headersArticleAssessmentInFrench = [
      { label: "Identifiant de l'utilisateur", key: "userId" },
      { label: "Email de l'utilisateur", key: "userEmail" },
      { label: "Lieu de l'utilisateur", key: "userLocation" },
      {
        label: "Heure d'inscription de l'utilisateur",
        key: "registrationTime",
      },
      { label: "Score obtenu", key: "score1" },
      { label: "Nombre total de points", key: "totalMarks" },
      { label: "Question sélectionnée", key: "questionSelected" },
      { label: "Réponse textuelle", key: "answer" },
      { label: "Heure de soumission", key: "submitTime" },
      { label: "Assigné par", key: "assignedBy" },
      { label: "Assigné à", key: "assignedAt" },
    ];

    this.headersArticleAssessmentInArabic = [
      { label: "معرف المستخدم", key: "userId" },
      { label: "بريد المستخدم الإلكتروني", key: "userEmail" },
      { label: "موقع المستخدم", key: "userLocation" },
      { label: "وقت تسجيل المستخدم", key: "registrationTime" },
      { label: "النتيجة المحصل عليها", key: "score1" },
      { label: "إجمالي الدرجات", key: "totalMarks" },
      { label: "السؤال المحدد", key: "questionSelected" },
      { label: "الإجابة النصية", key: "answer" },
      { label: "وقت التقديم", key: "submitTime" },
      { label: "المعين من قبل", key: "assignedBy" },
      { label: "التعيين في", key: "assignedAt" },
    ];

    this.headersSpeechAssessment = [
      { label: "UserId", key: "userId" },
      { label: "UserEmail", key: "userEmail" },
      { label: "UserLocation", key: "userLocation" },
      { label: "UserRegistrationTime", key: "registrationTime" },
      { label: "AccuracyPercentage", key: "accuracy" },
      { label: "FluencyPercentage", key: "fluency" },
      { label: "CompletenessPercentage", key: "completeness" },
      { label: "PronunciationPercentage", key: "pronscore" },
      { label: "AudioPath", key: "audioPath" },
      { label: "SubmitTime", key: "submitTime" },
      { label: "AssignedBy", key: "assignedBy" },
      { label: "AssignedAt", key: "assignedAt" },
    ];

    this.headersSpeechAssessmentInFrench = [
      { label: "Identifiant de l'utilisateur", key: "userId" },
      { label: "Email de l'utilisateur", key: "userEmail" },
      { label: "Lieu de l'utilisateur", key: "userLocation" },
      {
        label: "Heure d'inscription de l'utilisateur",
        key: "registrationTime",
      },
      { label: "Pourcentage de précision", key: "accuracy" },
      { label: "Pourcentage de fluidité", key: "fluency" },
      { label: "Pourcentage de complétude", key: "completeness" },
      { label: "Pourcentage de prononciation", key: "pronscore" },
      { label: "Chemin audio", key: "audioPath" },
      { label: "Heure de soumission", key: "submitTime" },
      { label: "Assigné par", key: "assignedBy" },
      { label: "Assigné à", key: "assignedAt" },
    ];

    this.headersSpeechAssessmentInArabic = [
      { label: "معرف المستخدم", key: "userId" },
      { label: "بريد المستخدم الإلكتروني", key: "userEmail" },
      { label: "موقع المستخدم", key: "userLocation" },
      { label: "وقت تسجيل المستخدم", key: "registrationTime" },
      { label: "نسبة الدقة", key: "accuracy" },
      { label: "نسبة الليونة", key: "fluency" },
      { label: "نسبة الاكتمال", key: "completeness" },
      { label: "نسبة النطق", key: "pronscore" },
      { label: "مسار الصوت", key: "audioPath" },
      { label: "وقت التقديم", key: "submitTime" },
      { label: "المعين من قبل", key: "assignedBy" },
      { label: "التعيين في", key: "assignedAt" },
    ];
  }

  setLocation = async () => {
    const allLocations =
      this.state.language === "fr"
        ? location_filter_In_French
        : this.state.language === "ar"
        ? location_filter_In_Arabic
        : location_filter;
  
    this.setState({
      allLocations,
      uLocation: allLocations[0],
    });
  };

  handleLocationOption = async (uL) => {
    await this.setState({ uLocation: uL });
  };

  onSelect1 = (value, states) => {
    this.setState({ value1:value, states });
    this.setState({
      customEnd1: originalMoment(this.state.value1.end).clone().add(1, "days"),
    });
    this.onToggle1()
  };

  onSelect2 = (value, states) => {
    this.setState({ value2:value, states });
    this.setState({
      customEnd2: originalMoment(this.state.value2.end).clone().add(1, "days"),
    });
    this.onToggle2()
  };

  onSelect3 = (value, states) => {
    this.setState({ value3:value, states });
    this.setState({
      customEnd3: originalMoment(this.state.value3.end).clone().add(1, "days"),
    });
    this.onToggle3()
  };

  onToggle1 = () => {
    this.setState({ isOpen1: !this.state.isOpen1 });
  };
  onToggle2 = () => {
    this.setState({ isOpen2: !this.state.isOpen2 });
  };
  onToggle3 = () => {
    this.setState({ isOpen3: !this.state.isOpen3 });
  };

  renderSelectionValue1 = () => {
    const { t } = this.props;
    return (
      <div className="mb-3">
        <div class="mdk-dividerr"></div>
        <div className="out_date">
          <h3>
            {this.state.value1.start.format("YYYY-MM-DD")}
            <span>{" " + t("Custom Analytics.to") + " "}</span>
            {this.state.value1.end.format("YYYY-MM-DD")}
          </h3>
        </div>
      </div>
    );
  };

  renderSelectionValue2 = () => {
    const { t } = this.props;
    return (
      <div className="mb-3">
        <div class="mdk-dividerr"></div>
        <div className="out_date">
          <h3>
            {this.state.value2.start.format("YYYY-MM-DD")}
            <span>{" " + t("Custom Analytics.to") + " "}</span>
            {this.state.value2.end.format("YYYY-MM-DD")}
          </h3>
        </div>
      </div>
    );
  };

  renderSelectionValue3 = () => {
    const { t } = this.props;
    return (
      <div className="mb-3">
        <div class="mdk-dividerr"></div>
        <div className="out_date">
          <h3>
            {this.state.value3.start.format("YYYY-MM-DD")}
            <span>{" " + t("Custom Analytics.to") + " "}</span>
            {this.state.value3.end.format("YYYY-MM-DD")}
          </h3>
        </div>
      </div>
    );
  };

  handleSubmitAll = async (e) => {
    const { t } = this.props
    try {
      let { data: consolidatedData } = await getConsolidatedReport();

      consolidatedData = consolidatedData.map((userScores) => {
        return {
          ...userScores,
          location: t(`location.${userScores?.location}`),
        };
      });

      this.setState({ consolidatedData }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  convertArabicToEnglishDate = (arabicDate) => {
    const arabicToEnglish = {
      '٠': '0',
      '١': '1',
      '٢': '2',
      '٣': '3',
      '٤': '4',
      '٥': '5',
      '٦': '6',
      '٧': '7',
      '٨': '8',
      '٩': '9'
    };

    return arabicDate.replace(/[٠-٩]/g, char => arabicToEnglish[char]);
  }

  convertEnglishToArabicDate = (englishDate) => {
    const englishToArabic = {
        '0': '٠',
        '1': '١',
        '2': '٢',
        '3': '٣',
        '4': '٤',
        '5': '٥',
        '6': '٦',
        '7': '٧',
        '8': '٨',
        '9': '٩'
    };

    return englishDate.replace(/\d/g, digit => englishToArabic[digit] || digit);
};

  handleSubmitRange = async (e) => {
    const { t, i18n } = this.props
    e.preventDefault();
    let start = this.state.value1.start.format("YYYY-MM-DD");
    start = this.convertArabicToEnglishDate(start);
    const end = this.state.value1.end.format("YYYY-MM-DD");
    let customEndD = this.state.customEnd1.format("YYYY-MM-DD");
    customEndD =  this.convertArabicToEnglishDate(customEndD);
    try {
      let { data: consolidatedData } = await getConsolidatedReport(
        start,
        customEndD,
        this.state.uLocation.value
      );

      consolidatedData = consolidatedData.map((userScores) => {
        return {
          ...userScores,
          location: t(`location.${userScores?.location}`),
          registrationTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.registrationTime?.split('T')?.[0]) : userScores?.registrationTime,
        };
      });

      this.setState({ consolidatedData }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  handleSubmitMcqAssessmentIndividualRange = async (e) => {
    const { t, i18n } = this.props
    e.preventDefault();
    let start = this.state.value2.start.format("YYYY-MM-DD");
    start = this.convertArabicToEnglishDate(start);
    const end = this.state.value2.end.format("YYYY-MM-DD");
    let customEndD = this.state.customEnd2.format("YYYY-MM-DD");
    customEndD =  this.convertArabicToEnglishDate(customEndD);
    try {
      let { data: mcqAssessmentData } = await getMcqAssessmentReport(
        start,
        customEndD,
        this.state.uLocation.value
      );

      mcqAssessmentData = mcqAssessmentData.map((userScores) => {
        return {
          ...userScores,
          userLocation: t(`location.${userScores?.userLocation}`),
          registrationTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.registrationTime?.split('T')?.[0]) : userScores?.registrationTime,
          assignedAt: i18n.language === "ar" ?  this.convertEnglishToArabicDate(userScores?.assignedAt?.split('T')?.[0]) : userScores?.assignedAt,
          submitTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.submitTime?.split('T')?.[0]) : userScores?.submitTime,
        };
      });
      this.setState({ mcqAssessmentData }, () => {
        setTimeout(() => {
          this.mcqAssessmentLink.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  handleSubmitListeningAssessmentIndividualRange = async (e) => {
    const { t, i18n } = this.props
    e.preventDefault();
    let start = this.state.value2.start.format("YYYY-MM-DD");
    start = this.convertArabicToEnglishDate(start);
    const end = this.state.value2.end.format("YYYY-MM-DD");
    let customEndD = this.state.customEnd2.format("YYYY-MM-DD");
    customEndD =  this.convertArabicToEnglishDate(customEndD);
    try {
      let { data: listeningAssessmentData } =
        await getListeningAssessmentReport(
          start,
          customEndD,
          this.state.uLocation.value
        );
        listeningAssessmentData = listeningAssessmentData.map((userScores) => {
          return {
            ...userScores,
            userLocation: t(`location.${userScores?.userLocation}`),
            registrationTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.registrationTime?.split('T')?.[0]) : userScores?.registrationTime,
            assignedAt: i18n.language === "ar" ?  this.convertEnglishToArabicDate(userScores?.assignedAt?.split('T')?.[0]) : userScores?.assignedAt,
            submitTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.submitTime?.split('T')?.[0]) : userScores?.submitTime,
          };
        });

      this.setState({ listeningAssessmentData }, () => {
        setTimeout(() => {
          this.listeningAssessmentLink.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  handleSubmitTypeSpeedAssessmentIndividualRange = async (e) => {
    const { t, i18n } = this.props
    e.preventDefault();
    let start = this.state.value2.start.format("YYYY-MM-DD");
    start = this.convertArabicToEnglishDate(start);
    const end = this.state.value2.end.format("YYYY-MM-DD");
    let customEndD = this.state.customEnd2.format("YYYY-MM-DD");
    customEndD =  this.convertArabicToEnglishDate(customEndD);
    try {
      let { data: typeSpeedAssessmentData } = await getTypeAssessmentReport(
        start,
        customEndD,
        this.state.uLocation.value
      );

      typeSpeedAssessmentData = typeSpeedAssessmentData.map((userScores) => {
        return {
          ...userScores,
          userLocation: t(`location.${userScores?.userLocation}`),
          registrationTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.registrationTime?.split('T')?.[0]) : userScores?.registrationTime,
          assignedAt: i18n.language === "ar" ?  this.convertEnglishToArabicDate(userScores?.assignedAt?.split('T')?.[0]) : userScores?.assignedAt,
          submitTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.submitTime?.split('T')?.[0]) : userScores?.submitTime,
        };
      });

      this.setState({ typeSpeedAssessmentData }, () => {
        setTimeout(() => {
          this.typeSpeedAssessmentLink.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  handleSubmitArticleAssessmentIndividualRange = async (e) => {
    const { t, i18n } = this.props
    e.preventDefault();
    let start = this.state.value2.start.format("YYYY-MM-DD");
    start = this.convertArabicToEnglishDate(start);
    const end = this.state.value2.end.format("YYYY-MM-DD");
    let customEndD = this.state.customEnd2.format("YYYY-MM-DD");
    customEndD =  this.convertArabicToEnglishDate(customEndD);
    try {
      let { data: articleAssessmentData } = await getArticleAssessmentReport(
        start,
        customEndD,
        this.state.uLocation.value
      );

      articleAssessmentData = articleAssessmentData.map((userScores) => {
        return {
          ...userScores,
          userLocation: t(`location.${userScores?.userLocation}`),
          registrationTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.registrationTime?.split('T')?.[0]) : userScores?.registrationTime,
          assignedAt: i18n.language === "ar" ?  this.convertEnglishToArabicDate(userScores?.assignedAt?.split('T')?.[0]) : userScores?.assignedAt,
          submitTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.submitTime?.split('T')?.[0]) : userScores?.submitTime,
        };
      });

      this.setState({ articleAssessmentData }, () => {
        setTimeout(() => {
          this.articleAssessmentLink.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  handleSubmitSpeechAssessmentIndividualRange = async (e) => {
    const { t, i18n } = this.props
    e.preventDefault();
    let start = this.state.value2.start.format("YYYY-MM-DD");
    start = this.convertArabicToEnglishDate(start);
    const end = this.state.value2.end.format("YYYY-MM-DD");
    let customEndD = this.state.customEnd2.format("YYYY-MM-DD");
    customEndD =  this.convertArabicToEnglishDate(customEndD);
    try {
      let { data: speechAssessmentData } = await getSpeechAssessmentReport(
        start,
        customEndD,
        this.state.uLocation.value
      );

      speechAssessmentData = speechAssessmentData.map((userScores) => {
        return {
          ...userScores,
          userLocation: t(`location.${userScores?.userLocation}`),
          registrationTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.registrationTime?.split('T')?.[0]) : userScores?.registrationTime,
          assignedAt: i18n.language === "ar" ?  this.convertEnglishToArabicDate(userScores?.assignedAt?.split('T')?.[0]) : userScores?.assignedAt,
          submitTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.submitTime?.split('T')?.[0]) : userScores?.submitTime,
        };
      });

      this.setState({ speechAssessmentData }, () => {
        setTimeout(() => {
          this.speechAssessmentLink.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  handleSubmitCandidateAssignmentProfileRange = async (e) => {
    const { t, i18n } = this.props
    e.preventDefault();
    let start = this.state.value3.start.format("YYYY-MM-DD");
    start = this.convertArabicToEnglishDate(start);
    const end = this.state.value3.end.format("YYYY-MM-DD");
    let customEndD = this.state.customEnd3.format("YYYY-MM-DD");
    customEndD =  this.convertArabicToEnglishDate(customEndD);
    try {
      let { data: assignmentProfileData } = await getAllUserAssignmentProfile(
        start,
        customEndD,
        this.state.uLocation.value
      );

      assignmentProfileData = assignmentProfileData.map((userScores) => {
        return {
          ...userScores,
          recruiterLocation: t(`location.${userScores?.recruiterLocation}`),
          candidateLocation: t(`location.${userScores?.candidateLocation}`),
          recruitmentStatus: (t(`Dashboard.${userScores?.recruitmentStatus}`)).includes("Dashboard") ? userScores?.recruitmentStatus : t(`Dashboard.${userScores?.recruitmentStatus}`),
          isAdmin: (t(`common.${userScores?.isAdmin}`)).includes("common") ? userScores?.isAdmin : t(`common.${userScores?.isAdmin}`),
          typingModule: (t(`common.${userScores?.typingModule}`)).includes("common") ? userScores?.typingModule : t(`common.${userScores?.typingModule}`),
          speechModule: (t(`common.${userScores?.speechModule}`)).includes("common") ? userScores?.speechModule : t(`common.${userScores?.speechModule}`),
          mcqModule: (t(`common.${userScores?.mcqModule}`)).includes("common") ? userScores?.mcqModule : t(`common.${userScores?.mcqModule}`),
          listeningModule: (t(`common.${userScores?.listeningModule}`)).includes("common") ? userScores?.listeningModule : t(`common.${userScores?.listeningModule}`),
          isArchived: (t(`common.${userScores?.isArchived}`)).includes("common") ? userScores?.isArchived : t(`common.${userScores?.isArchived}`),
          articleModule: (t(`common.${userScores?.articleModule}`)).includes("common") ? userScores?.articleModule : t(`common.${userScores?.articleModule}`),
          candidateRegistrationTime: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.candidateRegistrationTime?.split('T')?.[0]) : userScores?.candidateRegistrationTime,
          assessmentAssignedAt: i18n.language === "ar" ? this.convertEnglishToArabicDate(userScores?.assessmentAssignedAt?.split('T')?.[0]) : userScores?.assessmentAssignedAt,
        };
      });

      this.setState({ assignmentProfileData }, () => {
        setTimeout(() => {
          this.userAssignmentProfileLink.current.link.click();
        });
      });
    } catch (error) {
      throw error;
    }
  };

  async componentDidMount() {
    await this.setLocation();
    const language = localStorage.getItem("selectedLanguage");
    const { i18n } = this.props;
    i18n.changeLanguage(language);
    moment.locale("fr");
  }

  componentDidUpdate(prevProps, prevState) {
    if (i18n.language !== prevState.language) {
      this.setState({language: i18n.language})
      this.setLocation();
    }
  }

  render() {
    const { uLocation, allLocations } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <h1 style={{ marginBottom: "24px" }}>
          {t("Custom Analytics.Custom Analytics")}
        </h1>
        {/* Select Location */}
        <div className="row" style={{ marginBottom: "24px" }}>
          <div className="col-sm-3">
            <label>{t("Custom Analytics.Choose Location")}</label>

            <Select
              value={
                this.state.language === "fr"
                  ? location_filter_In_French[0]
                  : this.state.language === "ar"
                  ? location_filter_In_Arabic[0]
                  : location_filter[0]
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={this.handleLocationOption}
              options={allLocations}
              //  isClearable= {true}
              //  maxMenuHeight={150}
              //  menuPlacement="auto"
            />
          </div>
        </div>

        {/* Consolidated Results */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>
              {t(
                "Custom Analytics.Get Consolidated Result (User Registration Time Based)"
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row w-100">
              <div className="col-sm-8">
                <div>{this.renderSelectionValue1()}</div>
                <div>
                  <input
                    type="button"
                    value={t("Custom Analytics.Pick Date")}
                    onClick={this.onToggle1}
                    className="btn btn-secondary"
                  />
                </div>
                {this.state.isOpen1 && (
                <div dir='ltr'>
                  <DateRangePicker
                    value={this.state.value1}
                    onSelect={this.onSelect1}
                    singleDateRange={true}
                    locale={this.state.language}
                    maximumDate={moment().toDate()}
                  />
                </div>
                )}

              </div>

              <div className="col-sm-4 date_btn_area">
                <div className="date_btn_area">
                  <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={this.handleSubmitRange}
                  >
                    {t("Custom Analytics.Get Report")}
                  </button>

                  {/* <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={this.handleSubmitAll}
                    title="Default All Location and All Dates"
                  >
                    Get All Report
                  </button> */}
                </div>

                <CSVLink
                  headers={this.state.language == "fr"
                    ? this.headersConsolidatedInFrench
                    : this.state.language == "ar"
                    ? this.headersConsolidatedInArabic
                    : this.headersConsolidated}
                  data={this.state.consolidatedData}
                  filename="Semantify-Report.csv"
                  ref={this.csvLinkEl}
                ></CSVLink>
              </div>
            </div>

            {/* <br /> */}
            {/* <div class="mdk-divider"></div> */}
          </AccordionDetails>
        </Accordion>

        {/* Individual Results */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>
              {t(
                "Custom Analytics.Individual Results (Assessment Submit Time Based)"
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row w-100">
              <div className="col-sm-8">
                <div>{this.renderSelectionValue2()}</div>
                <div>
                  <input
                    type="button"
                    value={t("Custom Analytics.Pick Date")}
                    onClick={this.onToggle2}
                    className="btn btn-secondary"
                  />
                </div>

                {this.state.isOpen2 && (
                  <DateRangePicker
                    value={this.state.value2}
                    onSelect={this.onSelect2}
                    singleDateRange={true}
                    locale={this.state.language}
                    maximumDate={moment().toDate()}
                  />
                )}
              </div>

              <div className="col-sm-4 date_btn_area">
                <div className="date_btn_area">
                  <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={this.handleSubmitMcqAssessmentIndividualRange}
                  >
                    {t("Custom Analytics.Get MCQ Report")}
                  </button>

                  <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={
                      this.handleSubmitListeningAssessmentIndividualRange
                    }
                  >
                    {t("Custom Analytics.Get Listening Report")}
                  </button>

                  <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={
                      this.handleSubmitTypeSpeedAssessmentIndividualRange
                    }
                  >
                    {t("Custom Analytics.Get Typing Report")}
                  </button>

                  <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={this.handleSubmitArticleAssessmentIndividualRange}
                  >
                    {t("Custom Analytics.Get Article Report")}
                  </button>

                  <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={this.handleSubmitSpeechAssessmentIndividualRange}
                  >
                    {t("Custom Analytics.Get Speech Report")}
                  </button>
                </div>

                <CSVLink
                  headers={
                    this.state.language == "fr"
                      ? this.headersMcqAssessmentInFrench
                      : this.state.language == "ar"
                      ? this.headersMcqAssessmentInArabic
                      : this.headersMcqAssessment
                  }
                  data={this.state.mcqAssessmentData}
                  filename={t(
                    "Custom Analytics.Semantify-MCQAssessment-Report.csv"
                  )}
                  ref={this.mcqAssessmentLink}
                ></CSVLink>
                <CSVLink
                  headers={
                    this.state.language == "fr"
                      ? this.headersListeningAssessmentInFrench
                      : this.state.language == "ar"
                      ? this.headersListeningAssessmentInArabic
                      : this.headersListeningAssessment
                  }
                  data={this.state.listeningAssessmentData}
                  filename={t(
                    "Custom Analytics.Semantify-ListeningAssessment-Report.csv"
                  )}
                  ref={this.listeningAssessmentLink}
                ></CSVLink>
                <CSVLink
                  headers={
                    this.state.language == "fr"
                      ? this.headersTypeAssessmentInFrench
                      : this.state.language == "ar"
                      ? this.headersTypeAssessmentInArabic
                      : this.headersTypeAssessment
                  }
                  data={this.state.typeSpeedAssessmentData}
                  filename={t(
                    "Custom Analytics.Semantify-TypingSpeed-Report.csv"
                  )}
                  ref={this.typeSpeedAssessmentLink}
                ></CSVLink>
                <CSVLink
                  headers={
                    this.state.language == "fr"
                      ? this.headersArticleAssessmentInFrench
                      : this.state.language == "ar"
                      ? this.headersArticleAssessmentInArabic
                      : this.headersArticleAssessment
                  }
                  data={this.state.articleAssessmentData}
                  filename={t(
                    "Custom Analytics.Semantify-ArticleAssessment-Report.csv"
                  )}
                  ref={this.articleAssessmentLink}
                ></CSVLink>
                <CSVLink
                  headers={
                    this.state.language == "fr"
                      ? this.headersSpeechAssessmentInFrench
                      : this.state.language == "ar"
                      ? this.headersSpeechAssessmentInArabic
                      : this.headersSpeechAssessment
                  }
                  data={this.state.speechAssessmentData}
                  filename={t(
                    "Custom Analytics.Semantify-SpeechAssessment-Report.csv"
                  )}
                  ref={this.speechAssessmentLink}
                ></CSVLink>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>
              {t(
                "Custom Analytics.Get Assignment Report (Assignment Registration Time Based)"
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row w-100">
              <div className="col-sm-8">
                <div>{this.renderSelectionValue3()}</div>
                <div>
                  <input
                    type="button"
                    value={t("Custom Analytics.Pick Date")}
                    onClick={this.onToggle3}
                    className="btn btn-secondary"
                  />
                </div>

                {this.state.isOpen3 && (
                  <DateRangePicker
                    value={this.state.value3}
                    onSelect={this.onSelect3}
                    singleDateRange={true}
                    locale={this.state.language}
                    maximumDate={moment().toDate()}
                  />
                )}
              </div>

              <div className="col-sm-4 date_btn_area">
                <div className="date_btn_area">
                  <button
                    className="btn btn-outline-primary btn_inline ml-3 button_common"
                    onClick={this.handleSubmitCandidateAssignmentProfileRange}
                  >
                    {t("Custom Analytics.Get Assignment Report")}
                  </button>
                </div>

                <CSVLink
                  headers={this.state.language == "fr"
                    ? this.headersUserAssignmentProfileInFrench
                    : this.state.language == "ar"
                    ? this.headersUserAssignmentProfileInArabic
                    : this.headersUserAssignmentProfile}
                  data={this.state.assignmentProfileData}
                  filename="Semantify-AssignmentReport.csv"
                  ref={this.userAssignmentProfileLink}
                ></CSVLink>
              </div>
            </div>

            {/* <br /> */}
            {/* <div class="mdk-divider"></div> */}
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ExportAnalytics);
