export const quotesArray = [
  {
    quote: "A subset of machine learning is closely related to computational statistics, which focuses on making predictions using computers, but not all machine learning is statistical learning.",
    author: "Wiki",
  },
  {
    quote:
      "Cells can acquire specified function and carry out various tasks within the cell such as replication, DNA repair, protein synthesis, and motility. Cells are capable of specialization and mobility within the cell.",
    author: "Wiki",
  },
  {
    quote:
      "The individual amino acid residues are bonded together by peptide bonds and adjacent amino acid residues. The sequence of amino acid residues in a protein is defined by the sequence of a gene, which is encoded in the genetic code.",
    author: "Wiki",
  },
];

export const quotesArrayInFrench = [
  {
    quote:
      "Un sous-ensemble de l'apprentissage automatique est étroitement lié aux statistiques informatiques, qui se concentrent sur la réalisation de prédictions à l'aide d'ordinateurs, mais tout l'apprentissage automatique n'est pas qu'un apprentissage statistique.",
    author: "Wiki",
  },
  {
    quote:
      "Les cellules peuvent acquérir une fonction spécifique et effectuer diverses tâches au sein de la cellule, telles que la réplication, la réparation de l'ADN, la synthèse des protéines et la motilité. Les cellules sont capables de spécialisation et de mobilité au sein de la cellule.",
    author: "Wiki",
  },
  {
    quote:
      "Les résidus d'acides aminés individuels sont liés entre eux par des liaisons peptidiques et des résidus d'acides aminés adjacents. La séquence des résidus d'acides aminés dans une protéine est définie par la séquence d'un gène, qui est codée dans le code génétique.",
    author: "Wiki",
  },
];

export const quotesArrayInArabic = [
  {
    quote: "ترتبط مجموعة فرعية من التعلم الآلي ارتباطًا وثيقًا بالإحصاءات الحسابية، والتي تركز على إجراء تنبؤات باستخدام أجهزة الكمبيوتر، ولكن ليس كل التعلم الآلي هو تعلم إحصائي.",
    author: "Wiki",
  },
  {
    quote:
      "يمكن للخلايا الحصول على وظيفة محددة وتنفيذ مهام مختلفة داخل الخلية مثل النسخ المتماثل وإصلاح الحمض النووي وتخليق البروتين والحركة. الخلايا قادرة على التخصص والتنقل داخل الخلية.",
    author: "Wiki",
  },
  {
    quote:
      "يتم ربط بقايا الأحماض الأمينية الفردية معًا بواسطة روابط الببتيد وبقايا الأحماض الأمينية المجاورة. يتم تحديد تسلسل بقايا الأحماض الأمينية في البروتين من خلال تسلسل الجين المشفر في الشفرة الوراثية.",
    author: "Wiki",
  },
];

export const random = (array) =>
  array[Math.floor(Math.random() * array.length)];
export const allowedKeys = [
  "q",
  "w",
  "e",
  "r",
  "t",
  "y",
  "u",
  "i",
  "o",
  "p",
  "a",
  "s",
  "d",
  "f",
  "g",
  "h",
  "j",
  "k",
  "l",
  "z",
  "x",
  "c",
  "v",
  "b",
  "n",
  "m",
  "Q",
  "W",
  "E",
  "R",
  "T",
  "Y",
  "U",
  "I",
  "O",
  "P",
  "A",
  "S",
  "D",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "Z",
  "X",
  "C",
  "V",
  "B",
  "N",
  "M",
  ";",
  "'",
  ",",
  ".",
];
